import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from "prop-types"

import Layout from "../components/Layout"
import SEO from '../components/seo'

const AdvertisingDisclosure = ( {data} ) => {
  const advertisingDisclosurePage = data.staticPage;

  return (
      <Layout>
        <SEO
            title={advertisingDisclosurePage.metaTags.title}
            description={advertisingDisclosurePage.metaTags.description}
        />


        <h1>{advertisingDisclosurePage.title}</h1>

        <div className="page-body" dangerouslySetInnerHTML={{__html: advertisingDisclosurePage.body}}/>
      </Layout>
  )
};

AdvertisingDisclosure.propTypes = {
  data: PropTypes.object
};

export default () => (
    <StaticQuery
        query={graphql`
      query AdvertisingDisclosureQuery {
        staticPage(path: {eq: "advertising-disclosure"}) {
          path
          title
          body
          metaTags {
            title
            description
          }
        }
      }
    `}
        render={ (data) => <AdvertisingDisclosure data={data} /> }
    />
)
